// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { Section } from './types'
import cn from 'classnames'
import ChevronUp from '../Icons/ChevronUp'
import ChevronDown from '../Icons/ChevronDown'
import { usePathname } from 'next/navigation'
import { useUser } from '../../../../contexts/userContext'

const SectionElement = ({
  section: { text, Icon, subItems, redirection, route, protectedAction },
  activeSection,
  closeNavBar,
  isNavExpanded,
  setActiveSection,
  selectedSubItem,
  setSelectedSubItem,
  news = {},
}: {
  section: Section
  closeNavBar: () => void
  activeSection: string
  isNavExpanded: boolean
  setActiveSection: (sectionName: string) => void
  selectedSubItem: string
  setSelectedSubItem: (sectionName: string) => void
  news: any
}) => {
  const pathname = usePathname()
  const [isShowingSubItems, setIsShowingSubItems] = useState(false)
  const [isSectionActive, setIsSectionActive] = useState(false)
  const [sectionHasNews, setSectionHasNews] = useState(false)
  let user

  try {
    const { userState } = useUser()
    user = userState
  } catch (error) {
    console.error(error)
  }

  const isRestricted = protectedAction && (!user?.data?.id || user?.data.role === 'LimitedAccess')

  const onClickSection = () => {
    if (isRestricted) {
      protectedAction()
    } else {
      if (redirection) {
        setActiveSection(text)
        redirection()
      }
      if (!!subItems && subItems.length > 0 && !isShowingSubItems) {
        setIsShowingSubItems(true)
      } else {
        setIsShowingSubItems(false)
        if (!subItems || subItems.length === 0) {
          closeNavBar && closeNavBar()
        }
      }
    }
  }

  const onClickSubItem = (textSubsection, redirection) => {
    setActiveSection(text)
    setSelectedSubItem(textSubsection)
    redirection()
    closeNavBar && closeNavBar()
  }

  useEffect(() => {
    const [, ...mainPath] = pathname?.split('/') || []
    const _routes =
      route != null
        ? typeof route == 'string'
          ? [route.split('/')]
          : route.map((r) => r.split('/'))
        : []
    const isActive = _routes.some(
      (_route) =>
        JSON.stringify(subItems ? mainPath[0] : mainPath) ===
        JSON.stringify(subItems ? _route[0] : _route),
    )
    setIsSectionActive(isActive)
    if (subItems && !isActive) {
      setIsShowingSubItems(false)
    }
  }, [route, pathname])

  useEffect(() => {
    const hasNews = subItems ? subItems.some((s) => news[s.text]) : false
    setSectionHasNews(hasNews)
  }, [news])

  useEffect(() => {
    if (subItems && isSectionActive) {
      const [, , subPath] = pathname?.split('/') || []
      const currentSubItem = subItems.find(
        (item) => subPath?.toLowerCase() === item.text.toLowerCase().replace(/\s+/g, '-'),
      )

      if (currentSubItem) {
        setSelectedSubItem(currentSubItem.text)
      }
    }
  }, [pathname, isSectionActive, subItems])

  return (
    <>
      <li
        className={`mb-6 flex ${
          isRestricted ? 'text-howdy-disabled-dark' : 'cursor-pointer hover:text-howdy-hover-text'
        } group items-center justify-between`}
        key={text}
        onClick={onClickSection}
      >
        <div
          className={cn('flex items-center', {
            'text-howdy-primary-green': isSectionActive,
          })}
        >
          <div className='relative'>
            {sectionHasNews && (
              <div className='absolute right-0 top-0 z-10 h-[12px] w-[12px] rounded-full bg-[#ED4F00]' />
            )}
            <Icon
              iconColor={isSectionActive ? 'text-white' : 'text-howdy-primary-green'}
              color={
                isSectionActive ? 'bg-howdy-primary-green' : isRestricted ? 'bg-howdy-disabled' : ''
              }
              hoverColor={!isSectionActive ? 'group-hover:bg-howdy-hover-bg' : ''}
              interactive={!isRestricted}
            />
          </div>
          {isNavExpanded && <p className='ml-2 text-sm font-bold'>{text}</p>}
        </div>
        <div className='text-black group-hover:text-howdy-primary-green'>
          {isNavExpanded && subItems && !isShowingSubItems && <ChevronDown />}
          {isNavExpanded && subItems && isShowingSubItems && <ChevronUp />}
        </div>
      </li>

      {isNavExpanded && subItems && isShowingSubItems && (
        <ul className='block'>
          {subItems.map(({ text: textSubsection, redirection }) => {
            return (
              <div key={textSubsection} className='mb-6 ml-12 flex  items-center gap-2'>
                {news[textSubsection] && (
                  <div className='h-[12px] w-[12px] rounded-full bg-[#ED4F00]' />
                )}
                <li
                  className={cn(
                    'cursor-pointer text-sm hover:text-howdy-primary-green hover:underline',
                    {
                      'text-howdy-primary-green':
                        isSectionActive && selectedSubItem === textSubsection,
                    },
                  )}
                  onClick={() => onClickSubItem(textSubsection, redirection)}
                  key={`${text}-${textSubsection}`}
                >
                  {textSubsection}
                </li>
              </div>
            )
          })}
        </ul>
      )}
    </>
  )
}

export default SectionElement
