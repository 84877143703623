import React, { ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

interface ButtonStyles {
  primary: string
  secondary: string
  tertiary: string
  primaryRedesign: string
  secondaryRedesign: string
}

const typesClasses: ButtonStyles = {
  primary: clsx(
    'bg-black text-white',
    'py-[12px] px-[28px]',
    'leading-[28px] text-base',
    'group-hover:bg-howdy-light-green',
    'group-active:bg-howdy-primary-green',
  ),
  secondary: clsx(
    'bg-white text-black border border-black',
    'py-[11px] px-[27px]',
    'leading-[28px]',
    'group-hover:text-white group-hover:bg-howdy-light-green group-hover:border-none group-hover:py-[12px] group-hover:px-[28px]',
    'group-active:text-white group-active:bg-howdy-primary-green group-active:border-none group-active:py-[12px] group-active:px-[28px]',
  ),
  tertiary: clsx(
    'bg-transparent text-black font-bold px-0 py-[12px] decoration-1 underline-offset-2',
    'underline',
    'group-hover:text-howdy-light-green',
    'group-active:text-howdy-primary-green',
  ),
  primaryRedesign: clsx(
    'rounded-xl',
    'bg-[#449386]',
    'px-7 py-3',
    'text-white',
    'hover:bg-howdy-hover-text',
  ),
  secondaryRedesign: clsx(
    'rounded-xl',
    'border border-[#449386]',
    'px-7 py-3',
    'text-[#449386]',
    'hover:bg-howdy-hover-text hover:text-white',
  ),
}

const disabledStyles = {
  primary: '!bg-howdy-disabled !text-howdy-gray',
  secondary: '!bg-howdy-disabled !text-howdy-gray !border-howdy-gray',
  tertiary: '!text-howdy-gray',
  primaryRedesign: '!bg-howdy-disabled !text-howdy-gray',
  secondaryRedesign: '!bg-howdy-disabled !text-howdy-gray !border-howdy-gray',
}

export default function Button({
  children,
  style = 'primary',
  type = 'button',
  compact = false,
  disabled,
  onClick,
  fullWidth = false,
  className,
  wrapperClassName,
  childType = 'center',
}: ButtonHTMLAttributes<any> & {
  style?: 'primary' | 'secondary' | 'tertiary' | 'primaryRedesign' | 'secondaryRedesign'
  fullWidth?: boolean
  className?: string
  wrapperClassName?: string
  compact?: boolean,
  childType?: 'center' | 'leftAligned',
}) {
  const classNames = typesClasses[style as keyof typeof typesClasses]
  const disabledClassNames = disabled ? disabledStyles[style as keyof typeof typesClasses] : ''
  return (
    <div
      className={`${disabled ? '' : 'group'} ${fullWidth ? 'w-full' : ''} ${
        wrapperClassName ? wrapperClassName : ''
      }`}
    >
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={twMerge(
          fullWidth && 'w-full',
          'rounded-full',
          'transition-transform',
          'transition-shadow',
          'transition-colors',
          'duration-75',
          'ease-in',
          classNames,
          className,
          disabledClassNames,
          compact && 'rounded-lg',
        )}
      >
        <div
          className={clsx('flex gap-2 items-center', {
            'justify-center' : !childType.includes('leftAligned'),
            'justify-start' : childType.includes('leftAligned'),
            'font-bold': !style.includes('Redesign'),
            'min-h-[28px]': !compact,
          })}
        >
          {children}
        </div>
      </button>
    </div>
  )
}
