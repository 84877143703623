import Base from '@howdy/core/elements/Modal/Base'
import Button from '@howdy/core/elements/Button'
import Circle from '@howdy/core/elements/Circle'
import PenNib from '@howdy/core/elements/Icons/PenNib'

export default function ProviderTCModal({ display, acceptAction, closeAction, openTCAction, provider }) {
  return (
    <Base isOpen={display} onRequestClose={closeAction}>
      <div className='relative'>
        <div className='flex w-full justify-center p-4'>
          <Circle borderColor='#DCDCDC' bgColor='white' className='text-howdy-primary-green'>
            <PenNib />
          </Circle>
        </div>
        <div className='overflow-y-auto px-4'>
          <h1 className='mb-4 text-center font-worksans text-xl font-bold'>Sign up with {provider ? provider.charAt(0).toUpperCase() + provider.slice(1) : 'Google'}</h1>
          <p className='text-center font-worksans text-sm'>
            By clicking accept, you agree to our&nbsp;
            <span
              className='cursor-pointer font-semibold decoration-1 hover:underline xsm:underline'
              onClick={openTCAction}
            >
              Terms and Conditions
            </span>
          </p>
        </div>
      </div>
      <div className='flex w-full justify-center gap-x-5 pt-4 md:w-auto'>
        <Button fullWidth onClick={acceptAction}>
          Accept
        </Button>
      </div>
    </Base>
  )
}
