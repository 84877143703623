import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface RoundIconProps {
  children: ReactNode
  size?: number
  color?: string
  hoverColor?: string
}

export default function RoundIcon({
  children,
  size = 55,
  color = 'bg-white',
  hoverColor = '',
}: RoundIconProps) {
  const containerSize = size + 3
  const containerSizeStrings = `w-[${containerSize}px] h-[${containerSize}px]`

  return (
    <div className={`relative ${containerSizeStrings}`}>
      <div
        className={twMerge(
          `w-[${size}px] h-[${size}px]`,
          'relative flex items-center justify-center rounded-full',
          'border border-howdy-primary-green',
          'transition-colors duration-200',
          color === 'bg-howdy-primary-green' ? 'text-white' : 'text-howdy-primary-green',
          color,
          hoverColor,
          'group-active:bg-howdy-primary-green active:bg-howdy-primary-green',
          'group-active:text-white active:text-white',
        )}
      >
        <div>{children}</div>
      </div>
    </div>
  )
}
