import React from 'react'
import RoundIcon from '../elements/RoundIcon'
import GridFour from '../elements/Icons/GridFour'
import UsersThree from '../elements/Icons/UsersThree'
import Calculator from '../elements/Icons/Calculator'
import Buildings from '../elements/Icons/Buildings'
import Info from '../elements/Icons/Info'
import { Section } from '@howdy/core/elements/NavigationSideBar/types'
import OrdersCircle from '../elements/Icons/OrdersCircle'

export default function LeadsMenuSection({
  openTutorialVideo,
  openContactUs,
  openAboutUs,
  openRequestAccess,
  router,
}: {
  openTutorialVideo?: Function
  openContactUs?: Function
  openAboutUs?: Function
  openRequestAccess?: Function
  router?: any
}): Section[] {
  return [
    {
      Icon: ({ color, hoverColor, interactive }) => (
        <RoundIcon hoverColor={hoverColor} color={color} size={40} interactive={interactive}>
          <GridFour />
        </RoundIcon>
      ),
      text: 'Dashboard',
      route: 'dashboard',
      redirection: () => {
        router.push('/dashboard')
      },
      protectedAction: () => {
        openRequestAccess('/dashboard')
      },
    },
    {
      Icon: ({ color, hoverColor, interactive }) => (
        <RoundIcon hoverColor={hoverColor} color={color} size={40} interactive={interactive}>
          <UsersThree />
        </RoundIcon>
      ),
      text: 'My Team',
      route: ['my-teams', 'sharedProfile'],
      protectedAction: () => {
        openRequestAccess('/dashboard')
      },
      subItems: [
        {
          text: 'Add Teammate',
          redirection: () => {
            router.push('/my-teams/create-team')
          },
        },
        {
          text: 'Roster',
          redirection: () => {
            router.push('/my-teams/roster?status=Active')
          },
        },
        {
          text: 'Sourcing Pipeline',
          redirection: () => {
            router.push('/my-teams/sourcing-pipeline')
          },
        },
        {
          text: 'My Candidates',
          redirection: () => {
            router.push('/my-teams/my-candidates')
          },
        },
      ],
    },
    {
      Icon: ({ color, hoverColor, interactive }) => (
        <RoundIcon hoverColor={hoverColor} color={color} size={40} interactive={interactive}>
          <Calculator />
        </RoundIcon>
      ),
      text: 'Calculator',
      route: 'calculator',
      redirection: () => {
        router.push('/calculator')
      },
      protectedAction: () => {
        openRequestAccess('/calculator')
      },
    },
    {
      Icon: ({ color, hoverColor, interactive }) => (
        <RoundIcon hoverColor={hoverColor} color={color} size={40} interactive={interactive}>
          <Buildings />
        </RoundIcon>
      ),
      text: 'My Company',
      route: 'my-company',
      protectedAction: () => {
        openRequestAccess('/dashboard')
      },
      subItems: [
        {
          text: 'Admin',
          redirection: () => {
            router.push('/my-company/admin')
          },
        },
        {
          text: 'Company Info',
          redirection: () => {
            router.push('/my-company/company-info')
          },
        },
      ],
    },
    {
      Icon: ({ color, hoverColor, interactive }) => (
        <RoundIcon hoverColor={hoverColor} color={color} size={40} interactive={interactive}>
          <OrdersCircle />
        </RoundIcon>
      ),
      text: 'Store',
      route: 'store',
      redirection: () => {
        router.push('/store')
      },
      protectedAction: () => {
        openRequestAccess('/store')
      },
      subItems: [
        {
          text: 'Store',
          redirection: () => {
            router.push('/store')
          },
        },
        {
          text: 'Orders',
          redirection: () => {
            router.push('/store/orders')
          },
        },
      ],
    },
    {
      Icon: ({ color, hoverColor, interactive }) => (
        <RoundIcon hoverColor={hoverColor} color={color} size={40} interactive={interactive}>
          <Info width={22} height={22} />
        </RoundIcon>
      ),
      protectedAction: () => {
        openRequestAccess('/dashboard')
      },
      text: 'Information',
      subItems: [
        {
          text: 'Tutorial Video',
          redirection: () => {
            openTutorialVideo && openTutorialVideo()
          },
        },
        {
          text: 'About Us',
          redirection: () => {
            openAboutUs && openAboutUs()
          },
        },
        {
          text: 'Contact',
          redirection: () => {
            openContactUs && openContactUs()
          },
        },
      ],
    },
  ]
}
