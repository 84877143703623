import React from 'react'

export default function OrdersCircle({ width = 22, height = 23, color = 'currentColor' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.4932 4.45911H3.74319C3.34781 4.45911 3.02728 4.77963 3.02728 5.17502V18.0614C3.02728 18.4568 3.34781 18.7773 3.74319 18.7773H19.4932C19.8886 18.7773 20.2091 18.4568 20.2091 18.0614V5.17502C20.2091 4.77963 19.8886 4.45911 19.4932 4.45911Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.02728 7.32275H20.2091'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.1977 10.1863C15.1977 11.1357 14.8206 12.0462 14.1493 12.7175C13.478 13.3888 12.5675 13.7659 11.6182 13.7659C10.6688 13.7659 9.75835 13.3888 9.08706 12.7175C8.41576 12.0462 8.03864 11.1357 8.03864 10.1863'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
